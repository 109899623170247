import Vue from 'vue'
import Router from 'vue-router'
// original using boostrap
// import Home from '../views/Home3B.vue'

// below using tailwind with side by side of text and input form
import Home from '../views/Home3C.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/About.vue')
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: () => import('../views/Confirm.vue')
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('../views/Error.vue')
    },
    {
      path: '/edit:id',
      name: 'edit',
      component: () => import('../components/EditComponent.vue')
    },
    {
      path: '/list',
      name: 'list',
      component: () => import('../components/ListComponent.vue')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})