<template>
    <!-- component -->
<div class="h-screen md:flex">
	<div
		class="relative overflow-hidden md:flex w-2/3 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center hidden bkgnd">
		<div>
			<h1 class="text-green text-4xl font-sans left-h1">Life happens.</h1>
			<h1 class="text-green text-4xl font-sans left-h1">We will buy your land.</h1><br />
			<h2 class="text-green mt-1 left-h2 font-sans">Email us at <a href="mailto:sell@ibuylandusa.com">sell@ibuylandusa.com</a></h2>
			<h2 class="text-green mt-1 left-h2 font-sans">or call 828-203-6800 extension 1</h2>
			<h2 class="text-green mt-1 left-h2 font-sans">or enter and submit the form to the right.</h2>
			<h2 class="text-green mt-1 left-h2-img font-sans"><img src="../assets/rightarrow.png" width="83" height="41"></h2>
		</div>
	</div>
	<div class="flex md:w-1/3 justify-center py-10 items-center bg-green-300 formbox">
        <label class="text-danger">{{ errorfield }}</label>
        <form @submit.prevent="handleSubmitForm">
			<h1 class="text-gray-800 mb-1 font-sans text-xl">Please Enter Your Contact Information</h1><br />
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black-400" viewBox="0 0 20 20" fill="currentColor">
					<path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
						clip-rule="evenodd" />
				</svg>
					<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="your name" v-model="seller.name" required/>
			</div>
			<!-- got svg from https://www.svgrepo.com/svg/505957/phone-call -->
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black-400"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2.14089 4.4021C2.34025 2.92963 3.63956 2 4.99004 2H7.55848C8.84977 2 9.99619 2.82629 10.4045 4.05132L11.454 7.19963C11.905 8.55283 11.1086 10.0036 9.72482 10.3496C9.38646 10.4342 9.26975 10.8555 9.51637 11.1022L12.8978 14.4836C13.1445 14.7303 13.5658 14.6135 13.6504 14.2752C13.9964 12.8914 15.4472 12.095 16.8004 12.546L19.9487 13.5955C21.1737 14.0038 22 15.1502 22 16.4415V19.01C22 20.3604 21.0704 21.6598 19.5979 21.8591C18.9114 21.9521 18.211 22 17.5 22C8.93959 22 2 15.0604 2 6.5C2 5.78898 2.04794 5.08863 2.14089 4.4021Z" fill="#5b5757"></path> </g></svg>
				<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="your phone number" v-model="seller.phone" required/>
			</div>
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">

        <svg class="h-5 w-5 text-black-400"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="3" y="5" width="18" height="14" rx="2" />
              <polyline points="3 7 12 13 21 7" />
        </svg>
						<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="your email address" v-model="seller.email" required />
			</div>
			<div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 bg-green-500">
				<!-- <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black-400"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 8H8.01M11.5858 4.58579L19.5858 12.5858C20.3668 13.3668 20.3668 14.6332 19.5858 15.4142L15.4142 19.5858C14.6332 20.3668 13.3668 20.3668 12.5858 19.5858L4.58579 11.5858C4.21071 11.2107 4 10.702 4 10.1716V6C4 4.89543 4.89543 4 6 4H10.1716C10.702 4 11.2107 4.21071 11.5858 4.58579Z" stroke="#555555" stroke-width="0.9359999999999999" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>			 -->
				<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-black-400"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 8H8.01M11.5858 4.58579L19.5858 12.5858C20.3668 13.3668 20.3668 14.6332 19.5858 15.4142L15.4142 19.5858C14.6332 20.3668 13.3668 20.3668 12.5858 19.5858L4.58579 11.5858C4.21071 11.2107 4 10.702 4 10.1716V6C4 4.89543 4.89543 4 6 4H10.1716C10.702 4 11.2107 4.21071 11.5858 4.58579Z" stroke="#000000" stroke-width="0.9359999999999999" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
				<input class="pl-2 outline-none border-none font-sans bg-yellow-100" type="text" placeholder="offer id" v-model="seller.offerid" />
			</div>				
			<button type="submit" class="block w-full bg-indigo-500 mt-4 py-2 rounded-2xl text-white font-semibold mb-2 font-sans">Submit</button>
		</form>
	</div>
</div>
</template>
<script>
import axios from "axios";

export default {
  name: 'home',
  data() {
      return {
          seller: {
              name: '',
              email: '',
              phone: '',
              offerid: '',
              clientip:'0.0.0.0',
          },
          errorfield:"",
          selected:"",
      }
  },
  methods: {
      async handleSubmitForm() {
          // await fetch("https://checkip.amazonaws.com/", {mode: 'no-cors'}).then(res => res.text()).then(data => {this.seller.clientip = data; console.log("Client IP:", data)
          // })
          const configruntime = require('../configruntime');
          const port = configruntime.apiport;
          let apiURL = 'https://ibuylandusa.com/api/create-seller';
          if (configruntime.environment == 'dev') {
              apiURL = 'http://localhost:' + port + '/api/create-seller';
          }
          // console.log("using apiURL:", apiURL);
          this.errorfield = "";
          axios.post(apiURL, this.seller).then(() => {
            this.$router.push('/confirm')
            this.seller = {
              name: '',
              email: '',
              phone: '',
              offerid: '',
              clientip:'0.0.0.0'
            }
          }).catch(error => {
            if (error.response) {
              this.errorfield = error.response.data;
            }
          });
      }
  }
}
</script>

<style scoped>
    .bkgnd {    
    background-image: url('../assets/beachwalk2-50.png'); 
    background-size: cover;
    background-position: center;
	padding-bottom: 10%;
  }
  .left-h1 {
	font-size:30px;
	text-align: center;
  }
  .left-h2 {
	font-size:30px;
	text-align: center;
  }
  .left-h2-img {
	margin-left: auto;
    margin-right: auto;
    width: 8em;
  }
  /* unvisited link */
a:link {
  color: blue;
}

/* visited link */
a:visited {
  color: green;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: blue;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
.formbox {
	padding-bottom: 10%;
}
/* .formbox-field {
	bg-green-300
} */

</style>